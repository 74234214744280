import CoreGetters from '@/fw-modules/fw-core-vue/store/getters'

export default Object.assign(CoreGetters, {
  userPermissions(state) {
    console.log('state.session.permissions :>> ', state.session.permissions)
    return {
      isAnyManager: !!state.session.permissions?.['is-any-manager'],
      isGlobalManager: !!state.session.permissions?.['global-manager'],
      isLeagueManager: !!state.session.permissions?.['league-manager'],
      isTeamGroupManager: !!state.session.permissions?.['team-group-manager']
    }
  },
  getUserPermissions(state) {
    return state.session.permissions
  },
  isPermissionsLoaded(state) {
    return state.session.permissionsLoaded
  }
})
