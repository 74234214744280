<template>
  <div
    class="rounded-3xl overflow-hidden relative bg-center bg-cover text-center px-8 pt-5 pb-8 text-white flex flex-col shadow-xl group"
    :style="{
      backgroundImage: 'url(' + backgroundImage + ')',
      'min-height': size == 'lg' ? '20rem' : '14rem'
    }"
  >
    <div
      class="absolute top-0 left-0 right-0 bottom-0 px-7 pt-7 pb-2 text-white flex flex-col"
      style="z-index: 1"
      :class="{
        'justify-center': size != 'lg'
      }"
    >
      <div v-if="edition && showEdition" class="flex flex-col justify-start flex-1">
        <div class="text-lg font-bold text-left">
          <span class="juc-text-color">JUC&nbsp;</span>
          <span class="text-white">{{ edition }}</span>
        </div>
      </div>
      <div
        class="flex items-center"
        :class="{
          'gap-1': size != 'lg',
          'gap-5': size == 'lg'
        }"
      >
        <div class="flex-1 flex flex-col justify-center text-left gap-1">
          <div
            v-if="title != null && title.length > 0"
            class="font-extrabold uppercase"
            :class="{
              'text-xl': sportName == null,
              'text-base': sportName != null
            }"
          >
            <v-clamp :max-lines="3"> {{ title }}</v-clamp>
          </div>
          <div v-else class="text-opacity-50 flex-1">Sem título</div>
          <div v-if="sportName != null" class="text-2xl font-bold">
            {{ sportName }}
          </div>
          <div v-else class="text-sm font-semibold uppercase">
            {{ subtitle }}
          </div>
        </div>
        <div v-if="showSports" class="text-center flex flex-col items-center">
          <div
            class="text-lg font-extrabold"
            :class="{
              '-mb-3': size == 'lg',
              '-mb-5': size != 'lg'
            }"
          >
            {{ numSports }}
          </div>
          <SportsIcon
            :class="{
              'h-22 w-22': size != 'lg',
              'h-20 w-20': size == 'lg'
            }"
          />
          <div
            class="text-xs font-semibold h-10"
            :class="{
              '-mt-2': size == 'lg',
              '-mt-4': size != 'lg'
            }"
          >
            Modalidades
          </div>
        </div>
        <div v-if="showTeams" class="text-center flex flex-col items-center gap-0">
          <div
            class="text-lg font-extrabold"
            :class="{
              '-mb-3': size == 'lg',
              '-mb-5': size != 'lg'
            }"
          >
            {{ numTeams }}
          </div>
          <ApprovedTeamsIcon
            :class="{
              'h-22 w-22': size != 'lg',
              'h-20 w-20': size == 'lg'
            }"
          />
          <div
            class="text-xs font-semibold h-10"
            :class="{
              '-mt-2': size == 'lg',
              '-mt-4': size != 'lg'
            }"
          >
            Equipas<br />Aprovadas
          </div>
        </div>
        <div v-if="showPlayers" class="text-center flex flex-col items-center gap-0">
          <div
            class="text-lg font-extrabold"
            :class="{
              '-mb-3': size == 'lg',
              '-mb-5': size != 'lg'
            }"
          >
            {{ numPlayers }}
          </div>
          <ParticipantsIcon
            :class="{
              'h-22 w-22': size != 'lg',
              'h-20 w-20': size == 'lg'
            }"
          />
          <div
            class="text-xs font-semibold h-10"
            :class="{
              '-mt-2': size == 'lg',
              '-mt-4': size != 'lg'
            }"
          >
            Participantes
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-black h-full w-full top-0 left-0 absolute bg-opacity-40 group-hover:bg-opacity-50 as-cover"
      style="z-index: 0"
      :class="{
        'as-cover-black': overlayColor == 'black',
        'as-cover-green': overlayColor == 'green',
        'as-cover-blue': overlayColor == 'blue',
        'as-cover-orange': overlayColor == 'orange',
        'as-cover-green-dark': overlayColor == 'green-dark'
      }"
    ></div>
  </div>
</template>

<script>
import { LEAGUES_PER_TYPE } from '@/utils/index.js'
import SportsIcon from '@/components/icons/SportsIcon'
import ApprovedTeamsIcon from '@/components/icons/ApprovedTeamsIcon'
import ParticipantsIcon from '@/components/icons/ParticipantsIcon'
export default {
  name: 'CardLeague',
  components: {
    SportsIcon,
    ApprovedTeamsIcon,
    ParticipantsIcon
  },
  props: {
    showTeams: {
      type: Boolean,
      default: false
    },
    showPlayers: {
      type: Boolean,
      default: false
    },
    showSports: {
      type: Boolean,
      default: false
    },
    league: {
      type: Object
    },
    size: {
      type: String,
      default: 'md'
    },
    sport: {
      type: Object
    },
    edition: {
      type: String,
      default: null
    },
    showEdition: {
      type: Boolean,
      default: false
    },
    numPlayers: {
      type: Number,
      default: 0
    },
    numTeams: {
      type: Number,
      default: 0
    },
    numSports: {
      type: Number,
      default: 0
    }
  },
  computed: {
    sportName() {
      return this.sport?.modality.title[this.language] || null
    },
    language() {
      return this.$store.state.language || 'pt'
    },
    overlayColor() {
      return LEAGUES_PER_TYPE[this.league.type].overlay
    },
    backgroundImage() {
      return `https://static.fw.uc.pt/banners/${LEAGUES_PER_TYPE[this.league.type].background}.jpg`
    },
    leagues() {
      return LEAGUES_PER_TYPE
    },
    user() {
      return this.$store.getters.getUser
    },
    subtitle() {
      return this.league?.target?.[this.language] || null
    },
    title() {
      return this.league?.title?.[this.language] || null
    },
    // Checks
    isStudent() {
      return this.call.validations.is_student
    }
  }
}
</script>

<i18n>
  {
    "en": {},
    "pt": {}
  }
</i18n>

<style scoped>
.as-cover {
  background: rgb(50, 68, 87);
  background: linear-gradient(0deg, rgba(50, 68, 87, 0.95) 25%, rgba(50, 68, 87, 0.35) 85%);
}
.as-cover-black {
  background: rgba(0, 0, 0, 0.7);
}
.as-cover-green {
  background: rgba(132, 174, 23, 0.7);
}
.as-cover-blue {
  background: rgba(31, 169, 235, 0.7);
}
.as-cover-orange {
  background: rgba(247, 160, 38, 0.7);
}
.as-cover-green-dark {
  background: rgba(23, 85, 0, 0.7);
}
</style>
