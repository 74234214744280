<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {
      redirected: false
    }
  },
  computed: {
    userPermissions() {
      return this.$store.getters.getUserPermissions
    },
    currentRouteName() {
      return this.$route.name
    }
  },
  watch: {
    userPermissions(val) {
      console.log('userPermissions', val)
      if (
        val &&
        val['is-any-manager'] == true &&
        !this.redirected &&
        (this.currentRouteName == 'login' || this.currentRouteName == 'home')
      ) {
        this.redirected = true
        setTimeout(() => {
          this.$router.push({ name: 'manage' })
        }, 1)
      }
    }
  }
}
</script>

<style>
.juc-text-color {
  color: #e84e1b;
}
</style>
