import Vue from 'vue'
import Vuex from 'vuex'

import messages from '@/i18n'

import actions from './actions'
import getters from './getters'
import modules from './modules'
import mutations from './mutations'

import ActivityApi from '@/fw-modules/fw-core-vue/activity/services/ServiceActivity'
import ServiceSocialSupport from '@/services/ServiceCompetitions'
import CoreStoreDefaults from '@/fw-modules/fw-core-vue/store/defaults'

Vue.use(Vuex)

const state = Object.assign(CoreStoreDefaults.state, {
  language: localStorage.getItem('settings.language') || 'pt',
  locales: messages[localStorage.getItem('settings.language') || 'pt'],
  session: {
    error: false,
    errorType: false, // maxLoginAttemptsReached, InternalServerError
    errorDescription: '',
    token: localStorage.getItem('token') || '',
    appsLoaded: false,
    longLivedToken: false,
    user: {},
    apps: [],
    userPhoto: null,
    permissions: {},
    permissionsLoaded: false,
    roles: [],
    rolesLoaded: false
  },
  isDownloading: 0,
  isSaving: false,
  api: {
    base: ServiceSocialSupport,
    isActive: true
  },
  activityApi: {
    base: ActivityApi,
    isActive: true
  },
  socket: {
    connectionId: null
  },
  tmbScrollPos: {}
})

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules,
  getters
})
