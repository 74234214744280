<template>
  <div class="bg-white rounded-lg flex flex-col text-center items-center juc-text-color font-semibold p-10 gap-7">
    <div v-if="personal">Ainda não tens uma equipa :(</div>
    <TeamsPlaceholderIcon class="h-28" />
    <div v-if="personal">
      Entra na tua <span class="font-bold">liga</span>, escolhe um <span class="font-bold">torneio</span><br />e
      <span class="font-bold">inscreve</span> já a tua <span class="font-bold">equipa</span>
    </div>
    <div v-else>Sem equipas inscritas</div>
  </div>
</template>

<script>
import TeamsPlaceholderIcon from '@/components/icons/TeamsPlaceholderIcon.vue'
export default {
  name: 'PanelEmptyTeams',
  components: {
    TeamsPlaceholderIcon
  },
  props: {
    personal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
