var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded-3xl overflow-hidden relative bg-center bg-cover text-center px-8 pt-5 pb-8 text-white flex flex-col shadow-xl group",style:({
    backgroundImage: 'url(' + _vm.backgroundImage + ')',
    'min-height': _vm.size == 'lg' ? '20rem' : '14rem'
  })},[_c('div',{staticClass:"absolute top-0 left-0 right-0 bottom-0 px-7 pt-7 pb-2 text-white flex flex-col",class:{
      'justify-center': _vm.size != 'lg'
    },staticStyle:{"z-index":"1"}},[(_vm.edition && _vm.showEdition)?_c('div',{staticClass:"flex flex-col justify-start flex-1"},[_c('div',{staticClass:"text-lg font-bold text-left"},[_c('span',{staticClass:"juc-text-color"},[_vm._v("JUC ")]),_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.edition))])])]):_vm._e(),_c('div',{staticClass:"flex items-center",class:{
        'gap-1': _vm.size != 'lg',
        'gap-5': _vm.size == 'lg'
      }},[_c('div',{staticClass:"flex-1 flex flex-col justify-center text-left gap-1"},[(_vm.title != null && _vm.title.length > 0)?_c('div',{staticClass:"font-extrabold uppercase",class:{
            'text-xl': _vm.sportName == null,
            'text-base': _vm.sportName != null
          }},[_c('v-clamp',{attrs:{"max-lines":3}},[_vm._v(" "+_vm._s(_vm.title))])],1):_c('div',{staticClass:"text-opacity-50 flex-1"},[_vm._v("Sem título")]),(_vm.sportName != null)?_c('div',{staticClass:"text-2xl font-bold"},[_vm._v(" "+_vm._s(_vm.sportName)+" ")]):_c('div',{staticClass:"text-sm font-semibold uppercase"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])]),(_vm.showSports)?_c('div',{staticClass:"text-center flex flex-col items-center"},[_c('div',{staticClass:"text-lg font-extrabold",class:{
            '-mb-3': _vm.size == 'lg',
            '-mb-5': _vm.size != 'lg'
          }},[_vm._v(" "+_vm._s(_vm.numSports)+" ")]),_c('SportsIcon',{class:{
            'h-22 w-22': _vm.size != 'lg',
            'h-20 w-20': _vm.size == 'lg'
          }}),_c('div',{staticClass:"text-xs font-semibold h-10",class:{
            '-mt-2': _vm.size == 'lg',
            '-mt-4': _vm.size != 'lg'
          }},[_vm._v(" Modalidades ")])],1):_vm._e(),(_vm.showTeams)?_c('div',{staticClass:"text-center flex flex-col items-center gap-0"},[_c('div',{staticClass:"text-lg font-extrabold",class:{
            '-mb-3': _vm.size == 'lg',
            '-mb-5': _vm.size != 'lg'
          }},[_vm._v(" "+_vm._s(_vm.numTeams)+" ")]),_c('ApprovedTeamsIcon',{class:{
            'h-22 w-22': _vm.size != 'lg',
            'h-20 w-20': _vm.size == 'lg'
          }}),_c('div',{staticClass:"text-xs font-semibold h-10",class:{
            '-mt-2': _vm.size == 'lg',
            '-mt-4': _vm.size != 'lg'
          }},[_vm._v(" Equipas"),_c('br'),_vm._v("Aprovadas ")])],1):_vm._e(),(_vm.showPlayers)?_c('div',{staticClass:"text-center flex flex-col items-center gap-0"},[_c('div',{staticClass:"text-lg font-extrabold",class:{
            '-mb-3': _vm.size == 'lg',
            '-mb-5': _vm.size != 'lg'
          }},[_vm._v(" "+_vm._s(_vm.numPlayers)+" ")]),_c('ParticipantsIcon',{class:{
            'h-22 w-22': _vm.size != 'lg',
            'h-20 w-20': _vm.size == 'lg'
          }}),_c('div',{staticClass:"text-xs font-semibold h-10",class:{
            '-mt-2': _vm.size == 'lg',
            '-mt-4': _vm.size != 'lg'
          }},[_vm._v(" Participantes ")])],1):_vm._e()])]),_c('div',{staticClass:"bg-black h-full w-full top-0 left-0 absolute bg-opacity-40 group-hover:bg-opacity-50 as-cover",class:{
      'as-cover-black': _vm.overlayColor == 'black',
      'as-cover-green': _vm.overlayColor == 'green',
      'as-cover-blue': _vm.overlayColor == 'blue',
      'as-cover-orange': _vm.overlayColor == 'orange',
      'as-cover-green-dark': _vm.overlayColor == 'green-dark'
    },staticStyle:{"z-index":"0"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }