<template>
  <div>
    <div class="flex gap-5 pb-5">
      <JUCIcon class="h-16 w-16" />
      <div class="flex-1 flex flex-col">
        <div class="text-2xl font-bold juc-text-color">Jogos Universidade de Coimbra</div>
        <b-dropdown aria-role="list" position="is-bottom-right">
          <template #trigger="{}">
            <button
              type="button"
              class="text-lg font-semibold items-center juc-text-color flex"
              :label="currentEditionName"
            >
              {{ (currentEditionKey != null ? 'Edição ' : '') + currentEditionName }}
              <fw-icon-chevron-down class="h-5 w-5" />
            </button>
          </template>
          <b-dropdown-item
            v-for="item in editions"
            :key="item.key"
            aria-role="listitem"
            @click="selectEdition(item.key)"
            >{{ item.name }}</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>

    <fw-panel v-if="teams.length > 0" title="As minhas equipas" :counter="teams.length" class="my-5">
      <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
        <CardTeamDetailed
          v-for="team in teams"
          :key="team.key"
          :team="team"
          :users="users"
          :is-invite="!!team.invites?.key"
          :invite-status="team.invites?.status"
          :tournament="tournaments[team.tournament_key]"
          :sport="sports[tournaments[team.tournament_key]?.sport_key]"
          @click.native="goToTeam(team)"
        />
      </div>
    </fw-panel>
    <PanelEmptyTeams v-else :personal="true" />

    <fw-panel :title="'Ligas'" featured class="mt-10 mb-5">
      <template #after-heading>
        <span class="text-gray-500 font-semibold">disponíveis para esta edição</span>
      </template>

      <fw-panel-info v-if="!loading && !leagues?.length" type="basic" simple clean class="text-center text-gray-500">
        Não existem ligas na edição atual.
      </fw-panel-info>

      <LoadingPlaceholder v-if="loading" />
      <div v-else class="flex flex-col gap-5">
        <BannerLeague
          v-for="league in leagues"
          :key="league.key"
          :league="league"
          :num-players="league.stats?.total_players ?? 0"
          :num-teams="league.stats?.approved_teams ?? 0"
          :num-sports="league.stats?.total_sports ?? 0"
          :show-teams="true"
          :show-players="true"
          :show-sports="true"
          class="cursor-pointer"
          @click.native="goToLeague(league.key)"
        />
      </div>
    </fw-panel>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ leagues, teams, editions }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import CardTeamDetailed from '@/components/cards/CardTeamDetailed'
import BannerLeague from '@/components/banners/BannerLeague'
import JUCIcon from '@/components/icons/JUCIcon.vue'
import PanelEmptyTeams from '@/components/panels/PanelEmptyTeams.vue'
export default {
  components: {
    LoadingPlaceholder,
    CardTeamDetailed,
    BannerLeague,
    JUCIcon,
    PanelEmptyTeams
  },
  props: {},
  data() {
    return {
      teams: [],
      leagues: [],
      editions: [],
      tournaments: {},
      sports: {},
      users: {},
      loading: true,
      edition: null
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language || 'pt'
    },
    currentEditionName() {
      return this.editions.find(edition => edition.key === this.currentEditionKey)?.name ?? 'Selecione uma edição'
    },
    currentEditionKey() {
      return this.$route.query.edition ?? this.edition?.key
    }
  },

  watch: {
    currentEditionKey() {
      this.getHomeDashboard()
    }
  },

  mounted() {
    this.getHomeDashboard()
  },

  methods: {
    selectEdition(editionKey) {
      // this.currentEditionKey = editionKey
      // this.getLeagues()
      this.$router.push({
        name: 'home',
        query: {
          edition: editionKey
        }
      })
    },

    goToTeam(team) {
      if (!team) return

      if (team.invites?.key && team.invites?.status != 'invalidated') {
        return this.$router.push({
          name: 'team-invite',
          params: { tournamentKey: team.tournament_key, teamKey: team.key, inviteKey: team?.invites?.key }
        })
      }

      if (team.status == 'draft_1') {
        return this.$router.push({
          name: 'team-signup-edit',
          params: {
            teamKey: team.key,
            tournamentKey: team.tournament_key
          }
        })
      }

      return this.$router.push({
        name: 'team-view',
        params: { tournamentKey: team.tournament_key, teamKey: team.key }
      })
    },

    goToLeague(leagueKey) {
      this.$router.push({
        name: 'league-view',
        params: {
          editionKey: this.currentEditionKey,
          key: leagueKey
        }
      })
    },

    async getHomeDashboard() {
      this.loading = true
      try {
        const result = await this.api.getHomeDashboard(this.$route.query.edition)
        console.log('getTeams Result: ', result)

        if (result.edition) this.edition = result.edition
        else this.edition = result.editions?.[0]
        this.editions = result.editions
        this.leagues = result.leagues
        this.teams = result.teams
        this.users = result.users
        this.tournaments = result.tournaments
        this.sports = result.sports
      } catch (error) {
        console.error('getTeams Error: ', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "createdDate": "Data de criação",
    "calls": "Inscrições abertas",
    "nocalls": "Sem procedimentos a mostrar.",
    "callType": {
      "scholarship": "Apoio",
      "label": "Tipo"
    },
    "callStatus": {
      "published": "Publicado",
      "draft": "Rascunho",
      "closed": "Fechado",
      "archived": "Arquivado"
    },
    "all": "Todos",
    "mycalls": "As minhas inscrições",
    "orderBy": {
      "createdDate": "Data de criação",
      "title": "Título",
      "type": "Tipo",
      "status": "Estado",
      "endDate": "Data de fim",
      "startDate": "Data de início"
    }
  },
  "en": {
    "createdDate": "Creation date",
    "calls": "Inscrições abertas",
    "nocalls": "No calls to present.",
    "all": "All",
    "callType": {
      "scholarship": "Scholarship",
      "label": "Type"
    },
    "callStatus": {
      "published": "Published",
      "draft": "Draft",
      "closed": "Closed",
      "archived": "Archived"
    },
    "orderBy": {
      "createdDate": "Creation date",
      "title": "Title",
      "type": "Type",
      "status": "Status",
      "endDate": "End date",
      "startDate": "Start date"
    },
    "mycalls": "My calls"
  }
}
</i18n>
