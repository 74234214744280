export const LEAGUES_PER_TYPE = {
  academic: {
    title: { pt: 'Académica', en: 'Académica' },
    background: 'sports-09',
    overlay: 'black'
  },
  alumni: {
    title: { pt: 'Alumni', en: 'Alumni' },
    background: 'sports-05',
    overlay: 'orange'
  },
  'inter-residences': {
    title: { pt: 'Inter-Residências', en: 'Inter-Residências' },
    background: 'sports-06',
    overlay: 'green-dark'
  },
  '2is': {
    title: { pt: "2I's", en: "2I's" },
    background: 'sports-07',
    overlay: 'blue'
  },
  minerva: {
    title: { pt: 'Minerva', en: 'Minerva' },
    background: 'sports-08',
    overlay: 'green'
  },
  international: {
    title: { pt: 'Liga Internacional', en: 'Liga Internacional' },
    background: 'sports-10',
    overlay: 'green'
  },
  'pre-university': {
    title: { pt: 'Liga Pré-Universitária', en: 'Liga Pré-Universitária' },
    background: 'sports-12',
    overlay: 'green'
  },
  group: {
    title: { pt: 'Três Ligas', en: 'Três Ligas' },
    background: 'sports-01',
    overlay: 'green'
  }
}

export const LEAGUES_PER_TYPE_AS_ARRAY = Object.keys(LEAGUES_PER_TYPE).map(key => ({ key, ...LEAGUES_PER_TYPE[key] }))

export const EDITION_STATES = {
  draft: {
    label: { pt: 'Rascunho', en: 'Draft' },
    color: 'orange'
  },
  published: {
    label: { pt: 'Publicada', en: 'Published' },
    color: 'primary'
  }
}

export const TEAM_COLORS = ['#734DE1', '#E84E1B', '#B3C95A', '#40C274', '#29B0CE', '#EBC026']

export const TEAM_STATES = {
  draft_1: {
    label: { pt: 'Em rascunho', en: 'Draft' },
    color: 'medium'
  },
  draft_2: {
    label: { pt: 'Pendente', en: 'Registered' },
    color: 'medium'
  },
  submitted: {
    label: { pt: 'Submetida', en: 'Submitted' },
    color: 'orange'
  },
  approved: {
    label: { pt: 'Aprovada', en: 'Approved' },
    color: 'primary'
  },
  denied: {
    label: { pt: 'Rejeitada', en: 'Rejected' },
    color: 'danger'
  },
  cancelled: {
    label: { pt: 'Cancelado', en: 'Cancelado' },
    color: 'medium'
  },
  error: {
    label: { pt: 'Erro', en: 'Error' },
    color: 'danger'
  }
}

// Tournament stages status
export const TOURNAMENT_STATUS_TAG_COLORS = {
  'signup-open': 'primary',
  running: 'primary',
  inactive: 'medium',
  'signup-closed': 'medium',
  finished: 'medium',
  cancelled: 'medium',
  closed: 'medium'
}

// export const TEAM_STATUS_TAG_COLORS = {
//   draft_1: 'orange',
//   draft_2: 'orange',
//   submitted: 'primary',
//   cancelled: 'red',
//   approved: 'primary',
//   denied: 'red',
// }

export const TOURNAMENT_TYPES = {
  single_elimination: { pt: 'Eliminação direta', en: 'Single elimination' },
  double_elimination: { pt: 'Eliminação dupla', en: 'Double elimination' },
  round_robin: { pt: 'Round robin', en: 'Round robin' },
  swiss: { pt: 'Sistema suíço', en: 'Swiss system' }
}

export const TOURNAMENT_STAGES = {
  intragroups: { pt: 'Fase Intra', en: 'Intra Stage' },
  intergroups: { pt: 'Fase Inter', en: 'Inter Stage' },
  final: { pt: 'Fase Final', en: 'Final Stage' }
}

export const GENDERS = {
  M: { pt: 'Masculino', en: "Men's" },
  F: { pt: 'Feminino', en: "Women's" },
  U: { pt: 'Misto', en: 'Mixed' }
}

export const hasOnlyFinalStage = (league, sport) => {
  return (
    league?.type != 'academic' ||
    (league?.type == 'academic' && ['badminton', 'tennis', 'table_tennis'].includes(sport?.modality?.type))
  )
}

export const GROUPED_LEAGUES = ['2is', 'alumni', 'minerva']

export const ROUND_ROBIN_RANKING_OPTIONS = [
  {
    value: 'match_wins',
    label: 'Vitórias'
  },
  {
    value: 'game_wins',
    label: 'Vitórias em jogos/sets'
  },
  {
    value: 'game_win_percentage',
    label: '% de Vitórias em jogos/sets'
  },
  {
    value: 'game_w/l_difference',
    label: 'Game/Set W/L Difference'
  },
  {
    value: 'points_scored',
    label: 'Pontos Marcados'
  },
  {
    value: 'points_difference',
    label: 'Diferença de Pontos'
  },
  {
    value: 'custom',
    label: 'Personalizado (Sistema de pontos)'
  }
]
