import Home from '@/views/ViewHome'

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/:editionKey/league/:key',
    name: 'league-view',
    component: () => import('@/views/ViewLeague'),
    meta: { requiresAuth: true, view: 'sports' }
  },
  {
    path: '/:editionKey/league/:key/sport/:sportKey',
    name: 'league-view-sports',
    component: () => import('@/views/ViewSport'),
    meta: { requiresAuth: true, view: 'sport' }
  },
  {
    path: '/:editionKey/league/:key/sport/:sportKey/t/:tournamentKey',
    name: 'tournament-view',
    component: () => import('@/views/ViewTournament'),
    meta: { requiresAuth: true, view: 'dashboard' }
  },
  {
    path: '/:editionKey/league/:key/sport/:sportKey/t/:tournamentKey/s/:stageKey',
    name: 'league-view-stage',
    component: () => import('@/views/ViewStage'),
    meta: { requiresAuth: true, view: 'stage' }
  },
  {
    path: '/team/signup/:teamKey',
    name: 'team-signup-edit',
    component: () => import('@/views/ViewTeamSignup'),
    meta: { requiresAuth: true, view: 'signup' }
  },
  {
    path: '/:editionKey/league/:key/sport/:sportKey/t/:tournamentKey/signup',
    name: 'team-signup',
    component: () => import('@/views/ViewTeamSignup'),
    meta: { requiresAuth: true, view: 'signup' }
  },
  {
    path: '/invite/:tournamentKey/:teamKey/:inviteKey',
    name: 'team-invite',
    component: () => import('@/views/ViewInvitation'),
    meta: { requiresAuth: true }
  },
  {
    path: '/tournament/:tournamentKey/team/:teamKey',
    name: 'team-view',
    component: () => import('@/views/ViewTeam'),
    meta: { requiresAuth: true }
  },
  {
    path: '/notifications',
    name: 'notifications-view',
    component: () => import('@/fw-modules/fw-core-vue/notifications/views/SimpleNotifications'),
    meta: { requiresAuth: true }
  }
]
